import React, { createContext } from 'react';

import { useProvideAuth } from '../hooks/useAuth';
import { IAuthContext } from './authContextTypes';

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

// Context provider to wrap app.js
export function AuthProvider({ children }) {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={{
      confirmPasswordReset: auth.confirmPasswordReset,
      createUserWithEmailAndPassword: auth.createUserWithEmailAndPassword,
      hasFetchedUser: auth.hasFetchedUser,
      sendPasswordResetEmail: auth.sendPasswordResetEmail,
      signInWithEmailAndPassword: auth.signInWithEmailAndPassword,
      signinWithGoogle: auth.signinWithGoogle,
      signout: auth.signout,
      user: auth.user,
      verifyPasswordResetCode: auth.verifyPasswordResetCode,
    }}>
      {children}
    </AuthContext.Provider>
  );
}


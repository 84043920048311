import firebase from '../lib/firebase';
import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../context/authContext';
import { IAuthContext } from '../context/authContextTypes';
import { b64DecodeUnicode } from '../utils';

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = (): IAuthContext => useContext(AuthContext);

// Provider hook to create auth and handle state
export function useProvideAuth(): IAuthContext {
  const [user, setUser] = useState(null);
  const [hasFetchedUser, setHasFetchedUser] = useState(false);

  async function handleSetUser(user: firebase.User) {
    try {
      const token = await user.getIdToken();
      const tokenPayload = JSON.parse(b64DecodeUnicode(token.split('.')[1]));

      setUser({
        created_at: user.metadata.creationTime,
        displayName: user.displayName,
        email_verified: tokenPayload['email_verified'],
        email: user.email,
        photoURL: user.photoURL,
        picture: tokenPayload['picture'],
        role: tokenPayload['role'],
        token,
        uid: user.uid,
      });
      setHasFetchedUser(true);
    } catch (err) {
      console.error(err);
    }
  }

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signInWithEmailAndPassword = (
    email,
    password
  ): Promise<firebase.User> => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        handleSetUser(response.user);
        return response.user;
      });
  };

  const createUserWithEmailAndPassword = (
    email: string,
    password: string
  ): Promise<firebase.User> => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        handleSetUser(response.user);
        return response.user;
      });
  };

  const signout = (): Promise<void> => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      });
  };

  const sendPasswordResetEmail = (email): Promise<boolean> => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      })
      .catch(_err => {
        return false;
      });
  };

  const confirmPasswordReset = async (code, password): Promise<boolean> => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const signinWithGoogle = async (): Promise<void> => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithPopup(provider);
  };

  /**
   * @returns email_address of verified user
   */
  const verifyPasswordResetCode = async (
    resetCode: string
  ): Promise<string> => {
    return await firebase.auth().verifyPasswordResetCode(resetCode);
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        handleSetUser(user);
      } else {
        setUser(null);
        setHasFetchedUser(true);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    confirmPasswordReset,
    createUserWithEmailAndPassword,
    hasFetchedUser,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signinWithGoogle,
    signout,
    user,
    verifyPasswordResetCode,
  };
}

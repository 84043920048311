import firebase from 'firebase/app';

import fromUnixTime from 'date-fns/fromUnixTime';
import { AxiosError } from 'axios';

/**
 * Given a phone number, formats it
 * https://stackoverflow.com/a/8358141/628699
 */
export const formatPhoneNumber = (phoneNumberString: string): string => {
  if (!phoneNumberString) {
    return;
  }

  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

/**
 * Used when we know we have some sort of date but it's unclear what type
 * it's actually going to be. Can wrap with this and guarantee a JS Date output
 * @returns {Date}
 */
export const __UNSAFE__timestampToDate = (
  uncertainTimestamp: string | firebase.firestore.Timestamp | Date
): Date => {
  if (typeof uncertainTimestamp === 'undefined') {
    return null;
  }

  // Is a string representation of a date
  if (typeof uncertainTimestamp === 'string') {
    return new Date(uncertainTimestamp);
  }

  if (typeof uncertainTimestamp === 'number') {
    return fromUnixTime(uncertainTimestamp);
  }

  // Is a Date
  if ('getDate' in uncertainTimestamp) {
    return uncertainTimestamp;
  }

  // is firestore Timestamp value
  if ('toDate' in uncertainTimestamp) {
    return uncertainTimestamp.toDate();
  }
};

export function handleAxiosError(err) {
  if (err.isAxiosError) {
    const e = err as AxiosError;
    const out = e.toJSON();
    delete out['config'].auth;
    delete out['config'].data;

    return out;
  } else {
    return err;
  }
}

/**
 * Used to decode Firebase authentication tokens
 * https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
 * @param {string} input
 */
export function b64DecodeUnicode(input) {
  // Google uses a URL friendly Base64 encoder which adds -_ which need to be replaced with +/
  input = input.replace(/-/g, '+');
  input = input.replace(/_/g, '/');

  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(input)
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}
